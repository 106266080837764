import { SIGNING_OUT, SIGNING_OUT_STATE } from "./constants";

const isBrowser = () => typeof window !== 'undefined';

export const setLocalStoreString = (key, value) => {
  if (isBrowser()) {
    window.localStorage.setItem(key, value);
  }
};

export const getLocalStoreString = (key) => {
  if (isBrowser()) {
    return window.localStorage.getItem(key) || null;
  }
  return null;
};

export const getLocalStoreObject = (key) => {
  if (isBrowser()) {
    const storeString = window.localStorage.getItem(key) || null;
    try {
      if (storeString) {
        return JSON.parse(storeString);
      }
      return null;
    } catch (error) {
      console.log(`error getLocalStoreObject: ${key}`, error);
      return null;
    }
  }
  return null;
};

export const removeLocalStoreItem = (key) => {
  if (isBrowser()) {
    window.localStorage.removeItem(key);
  }
};

export const setSessionStoreString = (key, value) => {
  if (isBrowser()) {
    window.sessionStorage.setItem(key, value);
  }
};

export const getSessionStoreString = (key) => {
  if (isBrowser()) {
    return window.sessionStorage.getItem(key) || null;
  }
  return null;
};

export const removeSessionStoreItem = (key) => {
  if (isBrowser()) {
    window.sessionStorage.removeItem(key);
  }
};

export const clearSessionStore = () => {
  if (isBrowser()) {
    window.sessionStorage.clear();
  }
};

export const setSigningOutState = () => {
  if (isBrowser()) {
    sessionStorage.setItem(SIGNING_OUT_STATE, SIGNING_OUT);
  }
};

export const removeSigningOutState = () => {
  if (isBrowser()) {
    sessionStorage.removeItem(SIGNING_OUT_STATE);
  }
};

export const isSigningOutState = () => {
  if (isBrowser()) {
    return sessionStorage.getItem(SIGNING_OUT_STATE) === SIGNING_OUT;
  }
  return false;
};