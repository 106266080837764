import React from 'react';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { CacheProvider } from '@emotion/react';
import { SessionProvider } from 'next-auth/react';
import TagManager from 'react-gtm-module';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import createEmotionCache from '../styles/createEmotionCache';
import '../styles/global.css';
import theme from '../styles/theme';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { removeSigningOutState } from '../services/storageUtils';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const isProd = process.env.NODE_ENV ? (process.env.NODE_ENV === 'production' ? true : false) : true;

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps: { session, ...pageProps } }) {
  const [loaded, setLoaded] = React.useState(false);
  const [queryClient] = React.useState(() => new QueryClient());

  React.useEffect(() => {
    setLoaded(true);
    if (GTM_ID && GTM_ID.length > 0) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
    return () => {
      removeSigningOutState();
    };
  }, []);

  return (
    // <StyledEngineProvider injectFirst>
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Boys Town National Training</title>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
        <link rel='manifest' href='/manifest.webmanifest' />
        <script src='https://cmp.osano.com/AzZnUmURz9MuG4jO7/57a63fce-05c4-482f-830f-ced5f33104d5/osano.js'></script>
      </Head>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <SessionProvider session={session}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            {/* <CssBaseline /> */}
            {(isProd || loaded) && (
              <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={pageProps.dehydratedState}>
                  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-us'>
                    <Component {...pageProps} />
                  </LocalizationProvider>
                </HydrationBoundary>
                <ReactQueryDevtools />
              </QueryClientProvider>
            )}
          </SessionProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </CacheProvider>
    //</StyledEngineProvider>
  );
}

export default MyApp;
